<template>
  <div class="route-scheduling-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <Widget class="full-height-widget">
      <DataTable
        ref="routeSchedulingTable"
        class="full-height-data-table flexbox-item-grow p-datatable-gridlines p-datatable-sm"
        :value="getProjects"
        :paginator="true"
        :filters.sync="filters"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"
        stateStorage="local"
        stateKey="route-scheduling-table-filters"
        :loading="loading"
        :scrollable="true"
        scrollHeight="620px"
        style="width: 100%"
        :rows="1000"
        dataKey="id"
        :rowHover="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[25, 50, 100, 1000]"
        currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
        @filter="onFilter"
      >
        <template #header>
          <div class="table-header">
            <div class="table-header-left">
              <h1 class="page-title">{{ $t('route_scheduling') }}</h1>
            </div>
            <div class="table-header-right">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
              </span>
              <div>
                <multiselect
                  v-model="filters['employer.name']"
                  :options="employerNames()"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :multiple="true"
                  :taggable="true"
                  :placeholder="$t('employer')"
                  :show-labels="false"
                >
                </multiselect>
              </div>

              <button class="btn btn-bg mb-2" @click.prevent="onRemoveFilters()">
                <i class="fa fa-remove mr-3"></i>
                {{ $t('remove_filters') }}
              </button>
              <button
                v-if="isAdmin"
                class="btn btn-bg mb-2"
                @click.prevent="openRouteSchedulingsExport()"
              >
                <i class="fa fa-download mr-3"></i>
                {{ $t('export_view') }}
              </button>
            </div>
          </div>
        </template>

        <template #empty>
          {{ $t('no_data_found') }}
        </template>
        <template #loading>
          {{ $t('loading') }}
        </template>

        <Column field="number" header="#" sortable headerStyle="width: 60px">
          <template #body="slotProps">
            <router-link
              v-if="slotProps.data.number"
              :to="{
                name: 'ProjectEditPage',
                params: { projectNumber: slotProps.data.number },
              }"
              >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
            >
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['number']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="customer.lastname"
          :header="$t('lastname')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText type="text" v-model="filters['customer.lastname']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="customer.firstname"
          :header="$t('firstname')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['customer.firstname']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column field="customer.street" :header="$t('street')" sortable filterMatchMode="contains">
          <template #body="slotProps">
            {{ slotProps.data.customer.street }} {{ slotProps.data.customer.streetNumber }}
          </template>
          <template #filter>
            <InputText type="text" v-model="filters['customer.street']" class="p-column-filter" />
          </template>
        </Column>
        <Column field="customer.zip" :header="$t('zip')" sortable filterMatchMode="startsWith">
          <template #filter>
            <InputText type="text" v-model="filters['customer.zip']" class="p-column-filter" />
          </template>
        </Column>
        <Column field="customer.city" :header="$t('city')" sortable filterMatchMode="contains">
          <template #filter>
            <InputText type="text" v-model="filters['customer.city']" class="p-column-filter" />
          </template>
        </Column>
        <Column field="customer.phone" :header="$t('phone')" sortable filterMatchMode="contains">
          <template #filter>
            <InputText type="text" v-model="filters['customer.phone']" class="p-column-filter" />
          </template>
        </Column>
        <Column
          field="customer.mobile"
          :header="$t('mobile_short')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText type="text" v-model="filters['customer.mobile']" class="p-column-filter" />
          </template>
        </Column>

        <Column
          field="itemPlanningFinishedState"
          :header="$t('itemPlanningFinishedState_short')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <b-badge :variant="yesNoColor(slotProps.data.itemPlanningFinishedState)">
              {{ $t(slotProps.data.itemPlanningFinishedState) }}
            </b-badge>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['itemPlanningFinishedState']"
              :options="optionsYesNo()"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationTeam"
          :header="$t('installationTeam_short')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationTeam
              "
            >
              {{ $t(slotProps.data.latestInstallationAppointment.installationTeam) }}
            </span>
          </template>
          <template #editor="slotProps">
            <Dropdown
              v-model="slotProps.data.latestInstallationAppointment.installationTeam"
              :options="getInstallationTeams"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['latestInstallationAppointment.installationTeam']"
              :options="getInstallationTeams"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>
        <Column
          field="latestInstallationAppointment.calendarWeek"
          :header="$t('calendar_week_short')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.calendarWeek
              "
            >
              {{ slotProps.data.latestInstallationAppointment.calendarWeek }}
            </span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['latestInstallationAppointment.calendarWeek']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationAt"
          :header="$t('installationAt_short')"
          sortable
          filterMatchMode="custom"
          :filterFunction="filterDateFrom"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationAt
              "
            >
              <b-badge
                :variant="
                  yesNoColor(slotProps.data.latestInstallationAppointment.appointmentApprovedState)
                "
              >
                {{ $datetime(slotProps.data.latestInstallationAppointment.installationAt) }}
              </b-badge>
            </span>
          </template>
          <template #filter>
            <Calendar
              v-model="filters['latestInstallationAppointment.installationAt']"
              selectionMode="single"
              class="p-column-filter"
              dateFormat="dd.mm.yy"
              placeholder=">="
            >
              <!-- <template #footer
                ><Checkbox
                  v-model="filters['latestInstallationAppointment.installationAt']"
                  :binary="true"/></template
            > -->
            </Calendar>
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationStartTime"
          :header="$t('installationStartAt_short')"
          sortable
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationStartTime
              "
            >
              {{ $time(slotProps.data.latestInstallationAppointment.installationStartTime) }}
            </span>
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.installationType"
          :header="$t('installationType_short')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.installationType
              "
            >
              {{ $t(slotProps.data.latestInstallationAppointment.installationType + '_short') }}
            </span>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['latestInstallationAppointment.installationType']"
              :options="getInstallationTypes"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                {{ $t(slotProps.option) }}
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="solarPlant.guaranteeCode"
          :header="$t('guaranteeCode')"
          sortable
          filterMatchMode="contains"
        >
          <template #filter>
            <InputText
              type="text"
              v-model="filters['solarPlant.guaranteeCode']"
              class="p-column-filter"
            />
          </template>
        </Column>
        <Column
          field="solarPlant.solarPlantOverallPower"
          :header="$t('solarPlantOverallPower_short')"
          sortable
          filterMatchMode="gte"
        >
          <template #body="slotProps">
            {{ $n(slotProps.data.solarPlant.solarPlantOverallPower, 'decimal_triple') }}
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['solarPlant.solarPlantOverallPower']"
              class="p-column-filter"
              placeholder=">="
            />
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.requiredInstallationTimeinHours"
          :header="$t('requiredInstallationTimeinHours_short')"
          sortable
          filterMatchMode="contains"
        >
          <template #editor="slotProps">
            <date-picker
              lang="de"
              v-model="slotProps.data.latestInstallationAppointment.requiredInstallationTimeinHours"
              input-class="form-control"
              type="time"
              :minute-step="15"
              placeholder="Stunden"
              format="HH:mm"
              value-type="format"
            >
            </date-picker>
          </template>
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.latestInstallationAppointment &&
                  slotProps.data.latestInstallationAppointment.requiredInstallationTimeinHours
              "
            >
              {{ slotProps.data.latestInstallationAppointment.requiredInstallationTimeinHours }}
            </span>
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['latestInstallationAppointment.requiredInstallationTimeinHours']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column
          field="latestInstallationAppointment.appointmentApprovedState"
          :header="$t('appointmentApprovedState_short')"
          sortable
          filterMatchMode="equals"
        >
          <template #body="slotProps">
            <span v-if="slotProps.data.latestInstallationAppointment">
              <b-badge
                :variant="
                  yesNoColor(slotProps.data.latestInstallationAppointment.appointmentApprovedState)
                "
              >
                {{ $t(slotProps.data.latestInstallationAppointment.appointmentApprovedState) }}
              </b-badge></span
            >
          </template>
          <template #editor="slotProps">
            <Dropdown
              v-model="slotProps.data.latestInstallationAppointment.appointmentApprovedState"
              :options="optionsYesNo()"
              class="p-column-filter"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
          <template #filter>
            <Dropdown
              v-model="filters['latestInstallationAppointment.appointmentApprovedState']"
              :options="optionsYesNo()"
              class="p-column-filter"
              :showClear="true"
            >
              <template #option="slotProps">
                <b-badge :variant="yesNoColor(slotProps.option)">
                  {{ $t(slotProps.option) }}
                </b-badge>
              </template>
            </Dropdown>
          </template>
        </Column>

        <Column
          field="installation.description"
          :header="$t('description')"
          sortable
          filterMatchMode="contains"
        >
          <template #editor="slotProps">
            <InputText v-model="slotProps.data.installation.description" />
          </template>
          <template #filter>
            <InputText
              type="text"
              v-model="filters['installation.description']"
              class="p-column-filter"
            />
          </template>
        </Column>

        <Column field="employer.name" :header="$t('EMPLOYER_SHORT')" filterMatchMode="in" sortable>
        </Column>
      </DataTable>
    </Widget>
    <Dialog
      :visible="rowDialog"
      :style="{ width: '80%' }"
      header="Tourenplanung bearbeiten"
      :modal="true"
      class="p-fluid"
      v-if="project"
    >
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Projekt</strong>
          </legend>

          <b-card-group>
            <b-card title="Übersicht" class="mb-4 overview-card">
              <b-card-text class="overview-card-grid-container">
                <div>Projektnummer</div>
                <div>
                  <span v-if="project.number !== '_new'">{{
                    project.number.toString().padStart(4, '0')
                  }}</span>
                  <span v-else>{{ $t('_new') }}</span>
                </div>
                <div>Name</div>
                <div>{{ project.customer.lastname }}, {{ project.customer.firstname }}</div>
                <div>Adresse</div>
                <div>{{ project.customer.zip }}, {{ project.customer.city }}</div>
              </b-card-text>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Installationstermin</strong>
          </legend>
        </b-col>
      </b-row>

      <template #footer>
        <b-button @click.prevent="saveProject" variant="bg" type="submit" class="mr-xs"
          >{{ $t('save') }}
        </b-button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { getYesNoStateColor } from '@/helpers/colors';
import { yesNo } from '@/helpers/enums';
import DataTable from 'primevue/datatable';
import Dialog from 'primevue/dialog';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import DatePicker from 'vue2-datepicker';
import Checkbox from 'primevue/checkbox';
import isEqual from 'lodash/isEqual';
import Multiselect from 'vue-multiselect';

export default {
  name: 'RouteSchedulings',
  components: {
    Breadcrumbs,
    DataTable,
    Column,
    Widget,
    InputText,
    Dropdown,
    DatePicker,
    Dialog,
    Calendar,
    Multiselect,
    // Checkbox,
  },
  data() {
    return {
      loading: true,
      filters: {},
      sortField: null,
      sortOrder: null,
      editingRows: [],
      backupRows: [],
      rowDialog: true,
      project: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser', 'isAdmin']),
    ...mapGetters([
      'isLoading',
      'getProjects',
      'getInstallationTeams',
      'getInstallationTypes',
      'getProject',
      'getEmployers',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('route_scheduling') }];
    },
  },

  methods: {
    ...mapActions([
      'fetchProjects',
      'fetchInstallationTeams',
      'fetchInstallationTypes',
      'projectLocalUpdate',
      'updateProject',
      'updateUserSettings',
      'fetchUserAttachmentUrl',
      'fetchEmployers',
    ]),
    employerNames() {
      return this.getEmployers.map((employer) => {
        return employer.name;
      });
    },
    async openRouteSchedulingsExport() {
      const url = await this.fetchUserAttachmentUrl({
        userId: this.getCurrentUser.id,
        attachmentType: 'routeSchedulingExportAttachment',
      });

      window.open(url);
    },
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    optionsYesNo() {
      return yesNo();
    },
    // exportCSV() {
    //   this.$refs.routeSchedulingTable.exportCSV();
    // },
    onFilter(filter) {
      const cachedFilters = JSON.parse(localStorage.getItem('route-scheduling-table-filters'));
      // only update if filters have changed
      if (
        isEqual(filter.filters, cachedFilters.filters) &&
        filter.sortField === cachedFilters.sortField &&
        filter.sortOrder === cachedFilters.sortOrder
      ) {
        return;
      }
      this.updateUserSettings({
        userId: this.getCurrentUser.id,
        settings: {
          name: 'routeSchedulingsFilter',
          filters: filter.filters,
          sortField: filter.sortField,
          filterMatchModes: filter.filterMatchModes,
        },
      });
    },
    onRemoveFilters() {
      this.sortOrder = null;
      this.sortField = null;
      this.filters = {};
      this.updateUserSettings({
        userId: this.getCurrentUser.id,
        settings: {
          name: 'routeSchedulingsFilter',
          filters: {},
          sortField: null,
          filterMatchModes: {},
        },
      });
    },
  },
  async mounted() {
    await this.fetchEmployers();
    await this.fetchInstallationTeams();
    await this.fetchInstallationTypes();
    await this.fetchProjects();
    this.loading = false;
    // const filtersFromStorage = JSON.parse(localStorage.getItem('route-scheduling-table-filters'));
    // console.log(filtersFromStorage.filters['latestInstallationAppointment.installationAt']);
    // this.filters['latestInstallationAppointment.installationAt'] = [];
    // filtersFromStorage.filters['latestInstallationAppointment.installationAt'].map((date) => {
    //   this.filters['latestInstallationAppointment.installationAt'].push(this.$date(date));
    // });
  },
};
</script>

<style scoped lang="scss">
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(n),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(n) {
  text-align: right;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(1),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
  // width: 60px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(9),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(9) {
  text-align: center;
  width: 75px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(10),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(10) {
  width: 75px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(11),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(11) {
  text-align: center;
  width: 45px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(12),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(12) {
  width: 110px;
  text-align: center;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(13),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(13) {
  text-align: center;
  width: 50px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(14),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(14) {
  text-align: center;
  width: 65px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(16),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(16) {
  text-align: center;
  width: 70px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(17),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(17) {
  text-align: center;
  width: 50px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(18),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(18) {
  text-align: center;
  width: 70px;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(19),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(19) {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-thead > tr > th:nth-child(20),
::v-deep .p-datatable .p-datatable-tbody > tr > td:nth-child(20) {
  text-align: center;
  width: 60px;
}
.p-editable-column .mx-datepicker {
  width: 100%;
}
::v-deep .p-paginator {
  .p-paginator-current {
    margin-left: auto;
  }
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}

.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
.overview-card-grid-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 0.5em;
}

// Table Header Filter Calendar Dropdown Width
::v-deep .p-calendar .p-datepicker {
  min-width: 25rem;
}
</style>
